import React, { useState, useEffect } from "react"
import useInterval from "../utils/hooks/useInterval"
import random from "../utils/helpers/random"
import styled from "styled-components"
import Text from "../components/Text"

const CounterStyled = styled.div`
  display: flex;
  justify-content: center;

  span {
    margin: 2.5px;
    line-height: 0.8;
  }
`

const times = [60, 30]
const Counter = props => {
  const initialCountValue = props.getInitialCountValue()

  let [count, setCount] = useState(initialCountValue + props.addedMoney) // get value from contentful
  const goalAndExtra = props.goal + props.addedMoney

  useEffect(() => {
    if (count >= goalAndExtra) {
      setCount(goalAndExtra)
      localStorage.setItem("savedAmount", goalAndExtra)
    }
  }, [count, goalAndExtra])

  useInterval(
    () => {
      const shouldUpdate = Math.round(random(0, 1))
      if (props.shouldCount && times[shouldUpdate] === 30) {
        if (count < goalAndExtra) {
          setCount(count + 1)
          localStorage.setItem("savedAmount", count + 1)
        } else if (count >= goalAndExtra) {
          localStorage.setItem("savedAmount", goalAndExtra)
          setCount(goalAndExtra)
        }
      }
    },
    props.shouldCount ? 30000 : null
  ) // 30 seconds

  return (
    <CounterStyled justifyContent="center">
      {count
        .toString()
        .split("")
        .map((item, key) => (
          <Text
            bg={"gray"}
            as="span"
            fontSize={["20px", "60px"]}
            fontWeight="bold"
            key={key}
            p={["1rem", "1.5rem", "2rem"]}
          >
            {item}
          </Text>
        ))}
    </CounterStyled>
  )
}

export default Counter
