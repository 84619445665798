const getNextDate = () => {
  var tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)

  tomorrow.setHours(0)
  tomorrow.setMinutes(0)
  tomorrow.setSeconds(0)

  return tomorrow
}

export default getNextDate
