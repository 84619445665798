import getNextDate from "./getNextDate"
import { useEffect, useState } from "react"

export const useTicker = timeStamps => {
  const [windowLoaded, setWindowLoaded] = useState(false)
  const goal = 200000

  const days = {
    total: Math.round(
      (timeStamps.finish - timeStamps.start) / (60 * 60 * 24 * 1000)
    ),
    left: Math.round(
      (timeStamps.finish - timeStamps.today) / (60 * 60 * 24 * 1000)
    ),
  }
  const moneyPerDay = Math.round(goal / days.total)

  const checkIfDateExpired = () => {
    const today = Math.round(timeStamps.today)

    if (
      today >= parseInt(getItem("amountExpires")) ||
      !getItem("amountExpires")
    ) {
      return true
    }
    return false
  }

  const shouldCount = () => {
    var currentHour = new Date(timeStamps.today).getHours()
    if (
      timeStamps.today < timeStamps.start ||
      timeStamps.today > timeStamps.finish ||
      currentHour < 8
    ) {
      return false
    }
    return true
  }

  const setItem = (key, value) => {
    localStorage.setItem(key, value)
  }

  const getItem = key => {
    return localStorage.getItem(key)
  }

  const getInitialCountValue = () => {
    const tomorrow = Math.round(getNextDate().getTime())

    if (timeStamps.today < timeStamps.start) {
      return 0
    }

    if (timeStamps.today > timeStamps.finish) {
      return goal
    }

    if (checkIfDateExpired()) {
      setItem("amountExpires", tomorrow)
    } else {
      if (getItem("savedAmount")) {
        return parseInt(getItem("savedAmount"))
      }
    }

    const money = (days.total - days.left) * moneyPerDay

    return money === 0 ? 5449 : money
  }

  useEffect(() => {
    if (!windowLoaded) {
      if (typeof window !== "undefined" && window) {
        setWindowLoaded(true)
      }
    }
  }, [windowLoaded])

  return {
    windowLoaded,
    shouldCount,
    getInitialCountValue,
    checkIfDateExpired,
    goal,
  }
}
