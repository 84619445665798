import styled from "styled-components"

import {
  fontFamily,
  textAlign,
  display,
  space,
  lineHeight,
  fontWeight,
  fontSize,
  color,
  width,
  position,
  flexGrow,
} from "styled-system"

const Text = styled.div`
   ${fontFamily}
   ${textAlign}
   ${lineHeight}
   ${fontWeight}
   ${space}
   ${display}
   ${fontSize}
   ${color}
	 ${width}
	 ${flexGrow}
	 ${position}

   animation-delay: ${props => props.delay && props.delay + "ms"};
	 
`

Text.defaultProps = {
  fontFamily: "body",
  lineHeight: "copy",
}

export default Text
