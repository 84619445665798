import styled from "styled-components"
import Text from "./Text"

const Paragraph = styled(Text).attrs({
  as: "p",
})``

Paragraph.defaultProps = {
  lineHeight: "copy",
  fontSize: "p",
  size: "regular",
  fontFamily: "body",
  fontWeight: "regular",
}

export default Paragraph
