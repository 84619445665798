import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { width, maxWidth, height, space } from "styled-system"

export const RegularImage = styled.img`
  width: 100%;
  height: 100%;

  ${width}
  ${height}
  ${space}
	${maxWidth}
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  ${width}
  ${height}
  ${space}
`

export default Image
