import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import Image, { RegularImage } from "../components/Image"
import { Container, Flex, Box } from "../components/Grid"

const LogoBarSectionStyled = styled(Container)`
  position: relative;
`

const LogoBarSection = ({ img, logos }) => (
  <LogoBarSectionStyled>
    <Flex
      variant="large"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={["column", "row"]}
    >
      <Box>
        <Image
					alt="Mustaschkampen ikon"
					width={['60px', "110px"]}
          fluid={logos.mustaschBadge.childImageSharp.fluid}
          mb={[3, 0]}
        />
      </Box>
      <Box display="flex" alignItems="center" flexDirection={["column", "row"]}>
        <RegularImage alt="hultaforslogo" src={logos.hultaforsLogo.publicURL} />
        <RegularImage
          alt="snickers logo"
          mt={[4, "13px"]}
          ml={[0, 3]}
          src={logos.snickersLogo.publicURL}
        />
      </Box>
    </Flex>
  </LogoBarSectionStyled>
)

LogoBarSection.propTypes = {
  siteTitle: PropTypes.string,
}

LogoBarSection.defaultProps = {
  siteTitle: ``,
}

export default LogoBarSection
