import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import Image, { RegularImage } from "../components/Image"
import { Container, Flex, Box } from "../components/Grid"
import H1 from "../components/H1"
import { mq } from "../utils/styles/theme"
import Animate from "../components/LazyAnimation"

const AnimateStyled = styled(Animate)`
  background: red;

  .animated {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .Hero_Background {
    transform: scale(1.08);
    transition: 1s ease;
    overflow: hidden;
  }

  > .animated.in-view {
    .Hero_Background {
      transform: scale(1);
    }
  }
`

const HeroStyled = styled(Container)`
  /* height: 100vh; */
  position: relative;

  .Hero_Background {
    width: 100%;
    height: 100%;
    position: absolute !important;
    object-fit: cover;
    font-family: "object-fit: cover;";

    opacity: 80%;
    top: 0;
    left: 0;
  }

  .animated-title .animated {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  h1 {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    top: calc(100% - 100px);
    transform: translateY(-100%) translateX(-50%);
    left: 50%;
    ${mq.sm} {
      top: calc(100% - 230px);
    }
  }
`

const Hero = ({ logos }) => (
  <StaticQuery
    query={graphql`
      query {
				heroImage: allContentfulHeroImageBlock(limit:1) {
					nodes {
						id
						image {
							fluid(maxWidth: 1000, quality: 100) {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
				}
        # heroImg: file(relativePath: { eq: "header.jpg" }) {
        #   childImageSharp {
        #     fluid(maxWidth: 1000, quality: 100) {
        #       ...GatsbyImageSharpFluid_withWebp
        #     }
        #   }
        # }
      }
    `}
    render={data => (
      <HeroStyled bg="black" height={["500px", "100vh"]}>
        <AnimateStyled>
          <Image
            className="Hero_Background"
            fluid={data.heroImage.nodes[0].image.fluid}
					/>
        </AnimateStyled>
        <Flex variant="large" justifyContent="space-between">
          <Box>
            <Image width={['60px', '110px']} fluid={logos.mustaschBadge.childImageSharp.fluid} />
          </Box>
          <Box display="flex" alignItems="center" mt={["-20px", 0]}>
            <a
              href="http://www.hultaforsgroup.se/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <RegularImage
                maxWidth={"100px"}
                src={logos.hultaforsLogo.publicURL}
              />
            </a>
            <a
              href="https://www.snickersworkwear.se/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <RegularImage
                ml={[2, 4]}
                maxWidth={"85px"}
                mt={["20px", "15px"]}
                src={logos.snickersLogo.publicURL}
              />
            </a>
          </Box>
        </Flex>
        <Animate textBg className="animated-title">
          <H1 margin="0" color="white">
            #MeasureYourMoSaveABro
          </H1>
        </Animate>
      </HeroStyled>
    )}
  />
)

Hero.propTypes = {
  siteTitle: PropTypes.string,
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero
