import React from "react"
import styled from "styled-components"
import Paragraph from "../components/P"
import H2 from "../components/H2"
import { Container, Flex, Box } from "../components/Grid"
import Animate from "../components/LazyAnimation"

const transition = child =>
  `transform 0.4s ease-in-out ${child * 300}ms, color 0.3s ease-in ${child *
    100 +
    400}ms`

const TitleAnimation = styled(Animate)`
  span:before {
    transform: translateX(-50%) translateY(-51%) rotate(-1deg) scaleX(0);
    transform-origin: left center;
  }
  span:nth-child(1):before {
    transition: ${transition(1)};
  }

  span:nth-child(2):before {
    transition: ${transition(2)};
  }

  span:nth-child(3):before {
    transition: ${transition(3)};
  }

  .animated.in-view span:before {
    transform: translateX(-50%) translateY(-51%) rotate(-1deg) scaleX(1);
  }
`

const IntroSection = () => {
  return (
    <Container py={[4, 6]} bg="white">
      <Flex variant="large">
        <Box width={[1, 1, 1 / 2]}>
          <TitleAnimation>
            <H2 mt={"25px"} mb={[4]} ml={["25px"]} fontSize={["1.8rem", "2.8rem", "h2"]}>
              <span>Varje dag drabbas</span>
              <span
                style={{
                  marginLeft: "10px",
                }}
              >
                27 män - 10 000
              </span>
              <span> om året </span>
            </H2>
          </TitleAnimation>
        </Box>
        <Box width={[1, 1, 1 / 2]}>
          <Animate opacityAnimation delay={400} duration={1000}>
            <Paragraph fontWeight="bold" mt={0}>
              Hultafors är stolt huvudsponsor till Mustaschkampen 2019 som
              stöttar forskning och uppmärksammar prostatacancer – Sveriges
              vanligaste cancersjukdom.
            </Paragraph>
          </Animate>

          <Animate opacityAnimation delay={600} duration={1000}>
            <Paragraph>
              Det behövs mer forskning för att få fram säkra och effektiva
              diagnos-, och behandlingsmetoder.
            </Paragraph>
            <Paragraph>Var med och bidra!</Paragraph>
          </Animate>
        </Box>
      </Flex>
    </Container>
  )
}
export default IntroSection
