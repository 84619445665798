import { Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import React from "react"
import { Flex, Box } from "./Grid"
import P from "./P"
import Animate from "../components/LazyAnimation"
const InstaItemStyled = styled(Box).attrs({
  as: "a",
})`
  text-align: left;
  color: black;
  border-radius: 4px;
  text-decoration: none;
  transition: transform 0.3s;
  transition-timing-function: ease;
  &:hover {
  }

  p {
    margin: 0;
    padding: 10px 0;
    word-break: break-word;
  }

  .ProfileCircle {
    width: 1.87rem;
    height: 1.87rem;
    border-radius: 100%;
  }

  .CaptionWrapper {
    strong {
      font-weight: bold;
    }
    p:last-child {
      border-top: 1px solid #f1f1f5;
    }
  }

  .ImageWrapper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;

    > img {
      position: absolute;
      object-fit: cover;
      font-family: "object-fit: cover;";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
const findHashtags = searchText => {
  var regexp = /\B\#\w\w+\b/g

  const result = searchText.match(regexp)
  let text = searchText

  if (result) {
    result.forEach(
      item => (text = text.replace(item, `<strong>${item}</strong>`))
    )
  }

  return text
}

const InstaItem = ({ image, username, caption, url, index }) => (
  <Animate className="insta-item-animate" opacityAnimation delay={index * 100}>
    <InstaItemStyled
      target="_blank"
      href={url}
      bg="white"
      fontFamily="instagram"
      color="black"
    >
      <Flex p={[2]} alignItems="center">
        <Box mr={[2]} bg={"#C4C4C4"} className="ProfileCircle"></Box>
        <P fontWeight="bold" fontSize="pSmall" fontFamily="instagram">
          {username}
        </P>
      </Flex>
      <div className="ImageWrapper">
        <img src={`${url}/media/?size=l`} alt="instagram-image" />
      </div>
      <Flex px={2} pb={2} className="CaptionWrapper">
        <P color="blue" fontFamily="instagram" fontSize="pSmall">
          View more on instagram
        </P>
        <P
          width="100%"
          fontSize="pSmall"
          fontFamily="instagram"
          dangerouslySetInnerHTML={{ __html: findHashtags(caption) }}
        ></P>
      </Flex>
    </InstaItemStyled>
  </Animate>
)

InstaItem.propTypes = {
  siteTitle: PropTypes.string,
}

InstaItem.defaultProps = {
  siteTitle: ``,
}

export default InstaItem
