import { css } from "styled-components"
import { colors } from "./theme"
import { mq } from "./theme"

export default css`
  position: relative;
  z-index: 1;

  @media screen and (max-width: 320px) {
    font-size: 1.2rem;
  }

  &:before {
    content: "";
    position: absolute;
    transform: translateX(-50%) translateY(-51%) rotate(-1deg);
    top: 50%;
    padding: 16px;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: ${colors.red};
    z-index: -1;

    ${mq.sm} {
      padding: 25px;
    }
  }
`
