import styled from "styled-components"
import Box from "./Box"

const Container = styled(Box).attrs({
  className: "Container",
})`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

Container.defaultProps = {
  py: [4, 4],
  px: [3, 5],
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}

export default Container
