import styled from "styled-components"
import Text from "./Text"
import textBgCss from "../utils/styles/textBg"
const H1 = styled(Text).attrs({
  as: "h1",
})`
  word-break: break-word;
  position: relative;

  ${textBgCss}
`

H1.defaultProps = {
  fontSize: ["h1Small", "h1", "h1"],
  fontWeight: "bold",
  lineHeight: 1,
  fontFamily: "headlines",
}

export default H1
