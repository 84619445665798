import styled from "styled-components"
import Box from "./Box"
import { variant } from "styled-system"

import theme from "../../utils/styles/theme"

const Flex = styled(Box).attrs({})`
${variant({
  variants: {
    full: {
      maxWidth: "100%",
    },
    regular: {
      maxWidth: "900px",
    },
    large: {
      maxWidth: "1080px",
    },
  },
})}

	${variant({
    prop: "grid",
    variants: theme.gridCols,
  })}

	display: ${props => props.grid && "grid"};
`

Flex.propTypes = {}

Flex.defaultProps = {
  width: [1],
  flexWrap: "wrap",
  display: "flex",
  variant: "regular",
  position: "relative",
}

export default Flex
