import React from "react"
import { useTicker } from "../utils/helpers/ticker"
import styled, { keyframes, css } from "styled-components"
import { StaticQuery, graphql } from "gatsby"
import Image from "../components/Image"
import P from "../components/P"
import H2 from "../components/H2"
import H3 from "../components/H3"
import Animate from "../components/LazyAnimation"
import Counter from "../components/Counter"
import { Container, Flex, Box } from "../components/Grid"

const muschdance = keyframes`

	0% {transform: translateY(0);
		animation-timing-function: ease-in;
	}
	40% {}
	50% {transform: translateY(20px);
		height: 20px;
		animation-timing-function: ease-out;
	}
	55% {
		transform: translateY(30px);
		height: 15px; 
		animation-timing-function: ease-in;
		}
	65% {
		transform: translateY(15px);
		height: 20px; 
		animation-timing-function: ease-out;
		}
	95% {
		transform: translateY(0);
		animation-timing-function: ease-in;
	}
	100% {transform: translateY(0);
		animation-timing-function: ease-in;
	}
`

const bounce = css`
  animation: ${muschdance} 1s;
  animation-iteration-count: infinite;
`

const CounterSectionStyled = styled(Container)`
  position: relative;
  text-align: center;
  padding: 0 !important;

  u {
    text-decoration: underline;
  }

  .musch-dance {
    position: absolute;
    width: 100%;

    > .gatsby-image-wrapper {
      top: -50px;
    }
    ${bounce};
  }

  .Counter-Background {
    width: 100%;
    height: 100%;
    position: absolute !important;
    /* object-fit: cover; */
    top: 0;
    left: 0;
    opacity: 0.6;
    height: 800px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 1;
      transition: opacity 0.3s ease;
      background-image: linear-gradient(0deg, #060606, transparent);
    }
  }
`

const CounterSection = ({ addedMoney }) => {
  const {
    windowLoaded,
    getInitialCountValue,
    checkIfDateExpired,
    goal,
    shouldCount,
  } = useTicker({
    today: new Date().getTime(),
    start: new Date("2019-10-15T09:00:00").getTime(),
    finish: new Date("2019-11-30T14:00:00").getTime(),
    addedMoney: addedMoney,
  })

  return (
    <StaticQuery
      query={graphql`
        query {
          bgImage: file(relativePath: { eq: "measure.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          mustasch: file(relativePath: { eq: "musch.png" }) {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <CounterSectionStyled bg="black" color="white">
          <Image
            className="Counter-Background"
            fluid={data.bgImage.childImageSharp.fluid}
          />
          <Container>
            <Flex mt={[4, 7]}>
              <Box textAlign="center" width={1}>
                <div className="musch-dance">
                  <Image fixed={data.mustasch.childImageSharp.fixed} />
                </div>
                <Animate mt={4} mb={3} bg="red" slideUp>
                  <H2>Hur många millimeter mustasch kan du få i november?</H2>
                </Animate>
                <Animate textBg>
                  <H3
                    textAlign="center"
                    margin={"0 auto"}
                    my={[4, 5]}
                    width={["max-content !important"]}
                    redBg
                  >
                    #MeasureYourMoSaveABro
                  </H3>
                </Animate>
                <Animate opacityAnimation>
                  <P>
                    Vi har tagit fram vår Mustaschstock för att kunna bidra med
                    det vi kan bäst - nämligen att mäta. För varje såld
                    Mustaschstock går 5 kr till Mustaschkampen.
                  </P>
                </Animate>
              </Box>
            </Flex>
          </Container>

          <Container>
            <Flex>
              <Box>
                <Animate slideUp>
                  <H2>Mät din mustasch!</H2>
                </Animate>
                <Animate opacityAnimation>
                  <P>
										Som ni säkert känner till är det många som låter sin
                    mustasch växa fritt i novembermånad. Mustaschen är en
                    symbolisk handling för att visa att man stöttar och
                    uppmärksammar Mustaschkampen. Enligt mustaschstatistiken
                    växer din mustasch ca 1 cm/månad vilket borde bli 2,5
                    millimeter/vecka. Vi följer gärna din mustaschutveckling i
                    novembers alla fyra veckor.
                  </P>
                  <P>
                    Ladda upp en bild på dig när du mäter din mustasch med{" "}
                    <a
                      style={{
                        color: "white",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/explore/tags/measureyourmosaveabro/"
                    >
                      #MeasureYourMoSaveABro
                    </a>{" "}
                    – vi skänker ytterligare 1 kr/uppladdad millimeter till
                    Mustaschkampen 2019.
                  </P>
                  <P>Ju fler inlägg, desto mer bidrag. </P>
                </Animate>
              </Box>
            </Flex>
          </Container>
          <Container py={[0, 0]} pb={[0, 5]}>
            <Flex mb={[0]} justifyContent="center">
              <Box>
                <H3>Totalt bidrag just nu (SEK):</H3>
                {windowLoaded && (
                  <Counter
                    shouldCount={shouldCount()}
                    getInitialCountValue={getInitialCountValue}
                    checkIfDateExpired={checkIfDateExpired}
                    goal={goal}
                    addedMoney={addedMoney}
                  />
                )}
              </Box>
            </Flex>
          </Container>
        </CounterSectionStyled>
      )}
    />
  )
}

// export const query = graphql`
//   query {
//     heroImage: file(relativePath: { eq: "heroimage.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 1000, quality: 100) {
//           ...GatsbyImageSharpFluid_withWebp
//           presentationWidth
//         }
//       }
//     }
//   }
//   query additionalCounterValues {
//     allContentfulAdditionalMoney {
//       edges {
//         node {
//           amount
//         }
//       }
//     }
//   }
// `

export default CounterSection
