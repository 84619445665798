import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import HeroSection from "../sections/HeroSection"
import CounterSection from "../sections/CounterSection"
import IntroSection from "../sections/IntroSection"
import InstagramSection from "../sections/InstagramSection"
import FooterSection from "../sections/FooterSection"

const IndexPage = props => {
  const logos = {
    hultaforsLogo: props.data.hultaforsLogo,
    snickersLogo: props.data.snickersLogo,
    mustaschBadge: props.data.mustaschBadge,
  }

  const extraAmounts = props.data.allContentfulAdditionalMoney.edges

  const extraAmountsSum = extraAmounts.reduce(
    (accum, current) => accum + current.node.amount,
    0
  )

  return (
    <Layout>
      <div style={{ backgroundColor: "#060606" }}>
        <SEO title={"MeasureYourMoSaveABro"} />
        <HeroSection logos={logos} />
        <IntroSection />
        <CounterSection addedMoney={extraAmountsSum} />
        <InstagramSection />
        <FooterSection logos={logos} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    mustaschBadge: file(relativePath: { eq: "mustasch_badge.png" }) {
      childImageSharp {
				fluid(maxWidth: 1000, quality: 100) {
					...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    snickersLogo: file(relativePath: { eq: "snickers-logo.svg" }) {
      publicURL
    }
    hultaforsLogo: file(relativePath: { eq: "hultafors-logo.svg" }) {
      publicURL
    }
    allContentfulAdditionalMoney {
      edges {
        node {
          amount
        }
      }
    }
  }
`

export default IndexPage
