import { bool } from "prop-types"
import { Box } from "@rebass/grid"
import styled from "styled-components"

import {
  space,
  color,
  fontSize,
  width,
  height,
  maxHeight,
  minHeight,
  position,
  alignItems,
  textAlign,
  display,
  borders,
  flexDirection,
  compose,
  grid,
  flexbox,
  backgroundImage,
} from "styled-system"

const BoxStyled = styled(Box)`
	${compose(
    space,
    color,
    width,
    height,
    maxHeight,
    minHeight,
    fontSize,
    position,
    grid,
    flexbox,
    flexDirection,
    backgroundImage,
    textAlign,
    display,
    position,
    borders
  )}
		${props =>
      props.center &&
      `
			display: flex;
			justify-content: flex-end;
			flex-direction: column;
		`}

		${props =>
      props.bottom &&
      `
			display: flex;
			justify-content: center;
			align-items: flex-end;
			flex-direction: column;
		`}
`

BoxStyled.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...color.propTypes,
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
  ...fontSize.propTypes,
  ...position.propTypes,
  ...alignItems.propTypes,
  ...flexDirection.propTypes,
  ...textAlign.propTypes,
  ...borders.propTypes,
  center: bool,
}

export default BoxStyled
