import React from "react"
import styled from "styled-components"

import H3 from "../components/H3"
import InstaItem from "../components/InstaItem"
import { mq } from "../utils/styles/theme"
import { Container, Flex, Box } from "../components/Grid"
import instaposts from "../../content/instaposts.json"
import Animate from "../components/LazyAnimation"
import Masonry from "react-masonry-css"

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}

const InstagramSectionStyled = styled(Container)`
  text-align: center;
  padding: 0 !important;
  overflow: hidden;
  position: relative;
  padding-bottom: 0;

  .Instafeed-outer {
    position: relative;
    max-height: 1800px;
    height: 100%;
    overflow: hidden;
    padding-bottom: 0;

    &:after {
      content: "";
      width: 100%;
      height: 500px;
      bottom: 0%;
      left: 0;
      position: absolute;
      opacity: 1;
      z-index: 2;
      transition: opacity 0.3s ease;
      pointer-events: none;
      background-image: linear-gradient(0deg, #060606, transparent);

      ${mq.sm} {
        bottom: 0%;
      }
    }

    .overlay-text {
      width: 100vw;
      position: absolute;
      bottom: 10%;
      z-index: 3;
      width: auto;
      left: calc(50% - 10px);
      transform: translateX(-50%);

      a {
        text-decoration: none;
      }

      h3:before {
        transition: 0.3s ease;
      }
      &:hover h3:before {
        background-color: #bf0f00;
      }
      &:before {
        padding: 20px;
      }
    }
  }

  .Instafeed {
    &.-desktop {
      display: flex;
      margin-left: -5px; /* gutter size offset */
      max-width: 1080px;

      .Instafeed-column {
        padding: 5px;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    &.-mobile {
      padding-left: 20px;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scroll-snap-align: start;
      scroll-padding: 0%;
      -webkit-overflow-scrolling: touch;

      @media screen and (min-width: 700px) {
        display: none;
      }
    }
  }

  .Instafeed {
    .insta-item-animate {
      display: inline-block;
      margin-right: 15px;
      width: 100%;
      height: auto;

      > .animate {
        height: 100%;
      }
    }

    a {
      margin-top: 1.7em;
      scroll-snap-align: start;
      height: 100%;
      display: inline-block;
      width: 100%;
      min-width: 70vw;
      margin-right: 1.8em;

      @media screen and (min-width: 700px) {
        transform: translateY(10px);
        min-width: unset;
        margin: 0px 0 0.7em;

        &:hover {
          transform: translateY(0px);
        }
      }
    }
  }
`

const InstagramSection = () => {
  return instaposts.length ? (
    <InstagramSectionStyled color="white">
      <Container py={[4, 6]} pt={[2, 2]} px={[0, 4]} pl={[3, 4]}>
        <Flex
          variant="large"
          mt={[4]}
          justifyContent="center"
          paddingRight={[0, 6]}
        >
          <Animate slideUp>
            <Box textAlign="center">
              <H3 mb={0}>Tack till alla uppladdade millimetrar!</H3>
            </Box>
          </Animate>
        </Flex>
        <Container
          maxHeight={instaposts.length <= 12 ? "1800px" : "3600px"}
          px={[0, 0]}
          py={[2, 4]}
          className="Instafeed-outer"
        >
          <Animate textBg className="overlay-text">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/explore/tags/measureyourmosaveabro/"
            >
              <H3 redBg>#MeasureYourMoSaveABro</H3>
            </a>
          </Animate>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="Instafeed -desktop"
            columnClassName="Instafeed-column"
          >
            {instaposts &&
              instaposts.map((item, key) => (
                <InstaItem
                  className="insta-item"
                  {...item}
                  key={key}
                  index={key}
                />
              ))}
          </Masonry>
          <Flex className="Instafeed -mobile">
            {instaposts &&
              instaposts.map((item, key) => (
                <InstaItem
                  className="insta-item"
                  {...item}
                  key={key}
                  index={key}
                />
              ))}
          </Flex>
        </Container>
      </Container>
    </InstagramSectionStyled>
  ) : (
    false
  )
}

export default InstagramSection
