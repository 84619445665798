import React from "react"
import { useInView } from "react-intersection-observer"
import styled, { css } from "styled-components"
import { space, height, width, opacity } from "styled-system"
const opacityAnimation = css`
  .animated {
    opacity: 0;
    transition: 0.5s ease-in;

    &.in-view {
      opacity: 1;
    }
  }
`

const slideUp = css`
  .animated {
    transform: translateY(20px);
    transition: 0.5s ease-in;
    transition-property: opacity, transform;
    opacity: 0;

    &.in-view {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const textBg = css`
  .animated {
    color: transparent;

    h1,
    h2,
    h3 {
      color: transparent;
      transition: color 0.4s ease-in-out 600ms;
    }

    > *:before {
      transform: translateX(-50%) translateY(-51%) rotate(-1deg) scaleX(0);
      transform-origin: left center;
      transition: transform 0.4s ease-in-out 300ms;
    }

    &.in-view {
      h1,
      h3 {
        color: white;
      }
      *:before {
        transform: translateX(-50%) translateY(-51%) rotate(-1deg) scaleX(1);
      }
    }
  }
`

const AnimationStyled = styled.div`
  ${props => props.opacityAnimation && opacityAnimation}
  ${props => props.slideUp && slideUp}
  ${props => props.textBg && textBg}

	.animated {
    transition-delay: ${props => props.delay}ms;
    transition-duration: ${props => props.duration}ms;
  }

	${space}
	${width}
	${height}
	${opacity}
`

const LazyAnimation = ({ children, ...props }) => {
  const [ref, inView] = useInView({
    rootMargin: "-0px 0px",
    triggerOnce: true,
  })

  return (
    <AnimationStyled triggerOnce={true} ref={ref} {...props}>
      <div className={`animated ${inView ? "in-view" : ""}`}>{children}</div>
    </AnimationStyled>
  )
}

export default LazyAnimation
