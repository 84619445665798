import styled from "styled-components"
import Text from "./Text"
import textBgCss from "../utils/styles/textBg"

const H3 = styled(Text).attrs({
  as: "h3",
})`
  width: 100%;
  position: relative;

  ${props => props.redBg && textBgCss}
`

H3.defaultProps = {
  fontSize: ["h3Small", "h3"],
  fontWeight: "bold",
  lineHeight: "solid",
  fontFamily: "headlines",
}

export default H3
