import styled from "styled-components"
import Text from "./Text"
import textBg from "../utils/styles/textBg"

const H2 = styled(Text).attrs({
  as: "h2",
})`
  /* Hultafors Group prov */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;

  span {
    position: relative;
    margin: 15px 0;
    color: white;
    align-self: flex-start;

    ${textBg}
  }
`

H2.defaultProps = {
  fontSize: ["h2Small", "h2"],
  fontFamily: "headlines",
  fontWeight: "bold",
  lineHeight: 1,
  textDecoration: "none",
}

export default H2
